<template>
  <section v-if="errored">
    <p>Something went wrong. Please try again later.</p>
  </section>

  <section v-else>
    <div
      v-if="loading"
      data-aos="fade"
    >
      Loading&hellip;
    </div>
   <div
      v-else
      id="schools"
      class="row justify-content-center row-cols-1 row-cols-lg-2 row-cols-xl-3 g-5"
    >
      <div
        class="school col"
        v-for="school in schools"
        :key="school.title"
      >
        <div
          class="card"
          data-aos="fade"
        >
          <img
            v-if="getImg(school)"
            class="card-img-top"
            :src="require(`@/assets/img/${getImg(school)}`)"
            loading="lazy"
          />
          <div class="school__content card-body">
            <h2 class="school__title card-title">
              <a :href="school.$.href">{{school.title[0]}}</a>
            </h2>
            <div class="school__description">
              <p class="card-text">{{school.description[0]}}</p>
            <Btn
              color="dark"
              :href="school.$.href"
              size="sm"
              outline
            >Learn More<span class="sr-only"> about the {{school.title[0]}}</span></Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import xml2js from 'xml2js';
import _ from 'lodash';
import Btn from '@/components/Btn.vue';

export default {
  name: 'Schools',
  data() {
    return {
      schools: [],
      loading: true,
      errored: false,
    };
  },
  props: {
    dataURL: {
      type: String,
      required: true,
    },
  },
  components: {
    Btn
  },
  mounted() {
    this.getSchools()
  },
  methods: {
    async getSchools() {
      this.axios.get(this.dataURL)
        .then(response => {
          xml2js.parseString(response.data, (err, result) => {
            if (err) {
              // error handling
            } else {
              let items = result.entries.entry;

              this.schools = this.orderItems(items.filter(item => item.categories[0].includes('schools_colleges')));
              // console.log(this.directoryItems);
            }
          });
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => this.loading = false);
    },
    orderItems: function (items) {
      return _.orderBy(items, [item => _.lowerCase(item.title)], ['asc']);
    },
    getImg(school) {
      let img = null;
      if ('src' in school.image[0].large[0]) {
        img = school.image[0].large[0].src[0];
      }
      return img;
    },
  },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.school {
  &__content {
    padding-top: 1.5rem;
  }
  &__title {
    @include heading-base;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  &__description {
    font-size: 1rem;
  }
}
</style>
